<template>
    <div class="halfBG space">
        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap mb-md-5">
            <div class="bdPage">
                <h2>NIFTY 50</h2>
                <div class="d-flex align-items-center">
                    <p class="mb-0 d-inline-flex align-items-center line-1"><img width="20" class="me-2" src="assets/images/country_flag/india.webp" alt="Flag" title="India"> NSE <span class="green ms-2 border-start ps-2">+253.50 (1.50%)</span></p>
                </div>
            </div>
            <div class="rightSearch">
                <router-link to="/economy-compare" class="link d-flex align-items-center"><img width="20" src="assets/images/round-balance.svg" alt="icon" title="Grid" class="me-1">Compare <span class="green mx-1"> GDP </span> with other countries</router-link>
            </div>
        </div>
        <div class="sensex mb-md-5">
            <p>The SENSEX (BSE30) is a major stock market index which tracks the performance of 30 major companies listed on the Bombay Stock Exchange. The companies are chosen based on the liquidity, trading volume and industry representation. The SENSEX, is a free-float market capitalization-weighted index. The Index has a base value of 100 as of 1978-79.</p>
            <div class="compareChart">
                <div class="d-flex align-items-center justify-content-between p-3 bgLight">
                    <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                        <li><a href="javascript:void(0)"><img width="12" src="assets/images/trade.svg" alt="Icon" title="Trade"></a></li>
                        <li><a href="javascript:void(0)">1M</a></li>
                        <li><a href="javascript:void(0)">5M</a></li>
                        <li><a href="javascript:void(0)">15M</a></li>
                        <li><a href="javascript:void(0)">30M</a></li>
                        <li><a href="javascript:void(0)">1H</a></li>
                        <li><a href="javascript:void(0)">4M</a></li>
                        <li class="sortBy">
                            <select class="form-control form-select">
                                <option>All</option>
                                <option>Weekly</option>
                                <option>Monthly</option>
                                <option>Yearly</option>
                            </select>
                        </li>
                    </ul>
                    <div class="rightBlock d-inline-flex align-items-center">
                        <a href="javascript:void(0)" class="border-btn">17:59:06 (UTC+5:30)</a> 
                        <router-link to="/economy-compare" class="border-btn mx-2">Compare</router-link> 
                        <a href="javascript:void(0)" class="border-btn min-auto"><vue-feather type="maximize"></vue-feather> </a> 
                    </div>
                </div>
                <img src="assets/images/chart/growChart.svg" alt="Chart" title="GDP Groth">
            </div>
        </div>
        <div class="summary mb-md-5">
            <h5 class="medium">Summary</h5>
            <p>The BSE SENSEX fell 23 points or less than 0.1% to 49,877 in early trade on Thursday, after gaining in the prior two sessions and following an extended pullback in Wall Street overnight, amid uncertainty about inflation. FOMC April's meeting minutes showed Wednesday that it might be appropriate in upcoming meetings to begin discussing a tapering plan if the US economy continues to pick up strongly. Turning to coronavirus situations in India, the country today reported 276,110 new virus cases over the last 24 hours, bringing overall caseloads to 25.77 million. Meantime, New Delhi is unlikely to resume major exports of COVID-19 vaccines until at least October as it diverts shots for domestic use, reported Reuters quoting three government sources. In business news, Indian renewable energy company Adani Green Energy said it would buy SoftBank Group Corp-bazcked SB Energy Holdings for a value of $3.5 billion. Oil and Natural Gas Co and Sun Pharmaceutical Industry shed 2.2% and 1%, each.</p>
        </div>
        <div class="composition mb-md-5">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <h5 class="medium mb-0"><span class="green">NIFTY 50</span> Composition </h5>
                <ul class="chartColored d-flex align-items-center mb-0">
                   <li class="color1">Healthcare</li>
                   <li class="color2">Technology</li>
                   <li class="color3">FMCG</li>
                   <li class="color4">Automobile</li>
                   <li class="color5">Retail</li>
                </ul>
            </div>
            <img src="assets/images/chart/compositionChart.svg" alt="Chart" title="Composition Chart">
        </div>
        <div class="relatedNews">
            <h5 class="mb-4 medium">Related  News</h5>
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-lg-6 mb-4">
                        <div class="card-body d-flex align-items-start p-0">
                            <span class="flex-shrink-0"><img src="assets/images/news/related1.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 mb-4">
                        <div class="card-body d-flex align-items-start p-0">
                            <span class="flex-shrink-0"><img src="assets/images/news/related2.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="card-body d-flex align-items-start p-0">
                            <span class="flex-shrink-0"><img src="assets/images/news/related2.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="card-body d-flex align-items-start p-0">
                            <span class="flex-shrink-0"><img src="assets/images/news/related3.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },
}
</script>
