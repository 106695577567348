<template>
    <ul class="footerDropdown d-flex align-items-center justify-content-center h-100">
        <li :class="[{'active':tab == 'GDP'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('GDP')"> GDP </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'GDP'}]">
              <a class="dropdown-items" href="#">GDP</a>
              <a class="dropdown-items" href="#">GDP1</a>
              <a class="dropdown-items" href="#">GDP2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Markets'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Markets')"> Markets </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Markets'}]">
              <a class="dropdown-items" href="#">Markets</a>
              <a class="dropdown-items" href="#">Markets1</a>
              <a class="dropdown-items" href="#">Markets2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Labour'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Labour')"> Labour </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Labour'}]">
              <a class="dropdown-items" href="#">Labour</a>
              <a class="dropdown-items" href="#">Labour1</a>
              <a class="dropdown-items" href="#">Labour2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Prices'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Prices')"> Prices </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Prices'}]">
              <a class="dropdown-items" href="#">Prices</a>
              <a class="dropdown-items" href="#">Prices1</a>
              <a class="dropdown-items" href="#">Prices2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Health'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Health')"> Health </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Health'}]">
              <a class="dropdown-items" href="#">Health</a>
              <a class="dropdown-items" href="#">Health1</a>
              <a class="dropdown-items" href="#">Health2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Money'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Money')"> Money </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Money'}]">
              <a class="dropdown-items" href="#">Money</a>
              <a class="dropdown-items" href="#">Money1</a>
              <a class="dropdown-items" href="#">Money2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Trade'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Trade')"> Trade </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Trade'}]">
              <a class="dropdown-items" href="#">Trade</a>
              <a class="dropdown-items" href="#">Trade1</a>
              <a class="dropdown-items" href="#">Trade2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Government'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Government')"> Government </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Government'}]">
              <a class="dropdown-items" href="#">Government</a>
              <a class="dropdown-items" href="#">Government1</a>
              <a class="dropdown-items" href="#">Government2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Business'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Business')"> Business </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Business'}]">
              <a class="dropdown-items" href="#">Business</a>
              <a class="dropdown-items" href="#">Business1</a>
              <a class="dropdown-items" href="#">Business2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Consumer'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Consumer')"> Consumer </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Consumer'}]">
              <a class="dropdown-items" href="#">Consumer</a>
              <a class="dropdown-items" href="#">Consumer1</a>
              <a class="dropdown-items" href="#">Consumer2</a>
            </div>
        </li>
        <li :class="[{'active':tab == 'Economic Calendar'}]">
          <a href="#" class="dropdown-toggle medium" @click="setTab('Economic Calendar')"> Economic Calendar </a>
            <div class="dropdown-menu custom p-0" :class="[{'show':tab == 'Economic Calendar'}]">
              <a class="dropdown-items" href="#">Economic Calendar</a>
              <a class="dropdown-items" href="#">Economic Calendar1</a>
              <a class="dropdown-items" href="#">Economic Calendar2</a>
            </div>
        </li>
    </ul>
</template>
<script>
export default {
    data() {
        return {
            tab: ''
        }
    },
    methods:{
        setTab(val){
            if(this.tab == val){
                this.tab = ''
            }else{
                this.tab = val
            }
        }
    },
    mounted(){
        // dropdown-toggle medium
        let _that = this
        document.addEventListener("click",(e)=>{
            if(e.target.className != 'dropdown-toggle medium' && _that.tab != ''){
                _that.tab = ''
            }
        });
    }
}
</script>