<template>
    <ul class="leftTabList">
        <li>
            <a @click.prevent="$parent.marketTab = 'NIFTY'" :class="($parent.marketTab == 'NIFTY')?'active':''" href="javascript:void(0)" class="d-flex align-items-center justify-content-between">
                <span class="d-flex align-items-start">
                    <img width="12" class="mt-1 me-2" src="assets/images/up.svg" alt="Icon" title="Up">
                    <span class="flex-grow-1">
                        <p class="f-20 mb-2">NIFTY 50</p>
                        <p class="f-18 green mb-0">+238.00</p>
                    </span>
                </span>
                <span class="text-end">
                    <p class="f-20 mb-2 medium">17,150.25</p>
                    <p class="f-18 green mb-0">1.41%</p>
                </span>
            </a>
        </li>
        <li>
            <a @click.prevent="$parent.marketTab = 'SENSEX'" :class="($parent.marketTab == 'SENSEX')?'active':''" href="javascript:void(0)" class="d-flex align-items-center justify-content-between">
                <span class="d-flex align-items-start">
                    <img width="12" class="mt-1 me-2" src="assets/images/down.svg" alt="Icon" title="Down">
                    <span class="flex-grow-1">
                        <p class="f-20 mb-2">SENSEX</p>
                        <p class="f-18 red mb-0">-747.00</p>
                    </span>
                </span>
                <span class="text-end">
                    <p class="f-20 mb-2 medium">57,541.58</p>
                    <p class="f-18 green mb-0">2.21%</p>
                </span>
            </a>
        </li>
        <li>
            <a @click.prevent="$parent.marketTab = 'USDINR'" :class="($parent.marketTab == 'USDINR')?'active':''" href="javascript:void(0)" class="d-flex align-items-center justify-content-between">
                <span class="d-flex align-items-start">
                    <img width="12" class="mt-1 me-2" src="assets/images/down.svg" alt="Icon" title="Down">
                    <span class="flex-grow-1">
                        <p class="f-20 mb-2">USDINR</p>
                        <p class="f-18 red mb-0">-0.028</p>
                    </span>
                </span>
                <span class="text-end">
                    <p class="f-20 mb-2 medium">75.382</p>
                    <p class="f-18 green mb-0">-0.04%</p>
                </span>
            </a>
        </li>
        <li>
            <a @click.prevent="$parent.marketTab = 'US10Y'" :class="($parent.marketTab == 'US10Y')?'active':''" href="javascript:void(0)" class="d-flex align-items-center justify-content-between">
                <span class="d-flex align-items-start">
                    <img width="12" class="mt-1 me-2" src="assets/images/up.svg" alt="Icon" title="Up">
                    <span class="flex-grow-1">
                        <p class="f-20 mb-2">US10Y</p>
                        <p class="f-18 green mb-0">+238.00</p>
                    </span>
                </span>
                <span class="text-end">
                    <p class="f-20 mb-2 medium">99.2969</p>
                    <p class="f-18 green mb-0">1.451%</p>
                </span>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
}
</script>
