<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <bannerslider/>
            </div>
        </div>
        <section class="blockElement ideaMenu secondaryBG border-bottom multiMenu">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <bottomnavbar/>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement summary overflow-hidden">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3 space px-0">
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap border-bottom trade pb-3 mb-4" v-if="marketTab == 'NIFTY'">
                            <div class="bdPage">
                                <h2>Markets - India </h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item"><router-link class="green" to="/economic-overview"> Economic Overview </router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Market</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap border-bottom trade pb-3 mb-4" v-if="marketTab == 'SENSEX'">
                            <div class="bdPage">
                                <h2>Markets - India </h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item"><router-link class="green" to="/economic-overview"> Economic Overview </router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Currency</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap border-bottom trade pb-3 mb-4" v-if="marketTab == 'USDINR'">
                            <div class="bdPage">
                                <h2>Currency - India </h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item"><router-link class="green" to="/economic-overview"> Economic Overview </router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Currency</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap border-bottom trade pb-3 mb-4" v-if="marketTab == 'US10Y'">
                            <div class="bdPage">
                                <h2>Bonds - India </h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item"><router-link class="green" to="/economic-overview"> Economic Overview </router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Bonds</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <lefttab></lefttab>
                    </div>
                    <div class="col-12 col-lg-9 ps-lg-5">
                        <marketindia v-if="marketTab == 'NIFTY'"></marketindia>
                        <marketindia v-if="marketTab == 'SENSEX'"></marketindia>
                        <currencyindia v-if="marketTab == 'USDINR'"></currencyindia>
                        <bondsindia v-if="marketTab == 'US10Y'"></bondsindia>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import bottomnavbar from "../economic-overview/files/bottom_navbar";
import lefttab from "./files/left_tabs";
import marketindia from "./files/market_india";
import currencyindia from "./files/currency_india";
import bondsindia from "./files/bonds_india";
export default {
    data() {
        return {
            grossTab:'Forecast',
            marketTab : 'NIFTY'
        }
    },
    components: {
        bottomnavbar,bannerslider,lefttab,marketindia,currencyindia,bondsindia
    },     
}
</script>
