<template>
    <div class="halfBG space">
        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap mb-4">
            <div class="bdPage">
                <h2>USDINR</h2>
                <div class="d-flex align-items-center">
                    <p class="mb-0 medium red line-1">-0.028 (-0.04%)</p>
                </div>
            </div>
            <div class="rightSearch">
                <router-link to="/economy-compare" class="link d-flex align-items-center"><img width="20" src="assets/images/round-balance.svg" alt="icon" title="Grid" class="me-1">Compare <span class="green mx-1"> GDP </span> with other countries</router-link>
            </div>
        </div>
        <ul class="toplinks d-flex align-items-center mb-4 border-bottom trade pb-2 w-500">
            <li class="ps-0"><a @click.prevent="summaryTab = 'Summary'" :class="(summaryTab == 'Summary'?'active':'')" href="#">Summary</a></li>
            <li><a @click.prevent="summaryTab = 'Forecast'" :class="(summaryTab == 'Forecast'?'active':'')" href="#">Forecast</a></li>
            <li><a @click.prevent="summaryTab = 'Stats'" :class="(summaryTab == 'Stats'?'active':'')" href="#">Stats</a></li>
        </ul>
        <div class="dhowtable-list">
            <div class="sensex mb-md-5">
                <p>The USDINR spot exchange rate specifies how much one currency, the USD, is currently worth in terms of the other, the INR. While the USDINR spot exchange rate is quoted and exchanged in the same day, the USDINR forward rate is quoted today but for delivery and payment on a specific future date. </p>
                <div class="compareChart">
                    <div class="d-flex align-items-center justify-content-between p-3 bgLight">
                        <ul class="chartTimeline d-flex align-items-center justify-content-end mb-0">
                            <li><a href="javascript:void(0)"><img width="12" src="assets/images/trade.svg" alt="Icon" title="Trade"></a></li>
                            <li><a href="javascript:void(0)">1M</a></li>
                            <li><a href="javascript:void(0)">5M</a></li>
                            <li><a href="javascript:void(0)">15M</a></li>
                            <li><a href="javascript:void(0)">30M</a></li>
                            <li><a href="javascript:void(0)">1H</a></li>
                            <li><a href="javascript:void(0)">4M</a></li>
                            <li class="sortBy">
                                <select class="form-control form-select">
                                    <option>All</option>
                                    <option>Weekly</option>
                                    <option>Monthly</option>
                                    <option>Yearly</option>
                                </select>
                            </li>
                        </ul>
                        <div class="rightBlock d-inline-flex align-items-center">
                            <a href="javascript:void(0)" class="border-btn">17:59:06 (UTC+5:30)</a> 
                            <router-link to="/economy-compare" class="border-btn mx-2">Compare</router-link>
                            <a href="javascript:void(0)" class="border-btn min-auto"><vue-feather type="maximize"></vue-feather> </a> 
                        </div>
                    </div>
                    <img src="assets/images/chart/growChart.svg" alt="Chart" title="GDP Groth">
                </div>
            </div>
            <div class="summary mb-md-5">
                <h5 class="medium">Summary</h5>
                <p>The USDINR increased 0.3930 or 0.54% to 72.9040 on Tuesday June 1 from 72.5110 in the previous trading session.</p>
            </div>
            <div class="relatedPair mb-md-5">
                <ul class="toplinks d-flex align-items-center mb-4 border-bottom trade pb-2 w-500">
                    <li class="ps-0"><a @click.prevent="relatedTab = 'Related'" :class="(relatedTab == 'Related'?'active':'')" href="#">Related Pairs</a></li>
                    <li><a @click.prevent="relatedTab = 'Major'" :class="(relatedTab == 'Major'?'active':'')" href="#">Major Pairs</a></li>
                </ul>
                <div class="table-responsive nesting border">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Symbol</th>
                                <th>LTP</th>
                                <th>Change</th>
                                <th>Daily</th>
                                <th>Weekly</th>
                                <th>Monthly</th>
                                <th>Yearly</th>
                                <th>YTD</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><img src="assets/images/chart/relatedPair.svg" alt="Chart" title="Related Pairs Chart"></td>
                                <td>USDINR</td>
                                <td>6.02</td>
                                <td>0.003</td>
                                <td><span class="btn gradient1">0.63%</span></td>
                                <td><span class="btn gradient2">2.63%</span></td>
                                <td><span class="btn gradient3">6.43%</span></td>
                                <td><span class="btn gradient4">58.48%</span></td>
                                <td><span class="btn gradient5">11.48%</span></td>
                            </tr>
                            <tr>
                                <td><img src="assets/images/chart/relatedPair.svg" alt="Chart" title="Related Pairs Chart"></td>
                                <td>USDINR</td>
                                <td>6.02</td>
                                <td>0.003</td>
                                <td><span class="btn gradient1">0.63%</span></td>
                                <td><span class="btn gradient2">2.63%</span></td>
                                <td><span class="btn gradient3">6.43%</span></td>
                                <td><span class="btn gradient4">58.48%</span></td>
                                <td><span class="btn gradient5">11.48%</span></td>
                            </tr>
                            <tr>
                                <td><img src="assets/images/chart/relatedPair.svg" alt="Chart" title="Related Pairs Chart"></td>
                                <td>USDINR</td>
                                <td>6.02</td>
                                <td>0.003</td>
                                <td><span class="btn gradient1">0.63%</span></td>
                                <td><span class="btn gradient2">2.63%</span></td>
                                <td><span class="btn gradient3">6.43%</span></td>
                                <td><span class="btn gradient4">58.48%</span></td>
                                <td><span class="btn gradient5">11.48%</span></td>
                            </tr>
                            <tr>
                                <td><img src="assets/images/chart/relatedPair.svg" alt="Chart" title="Related Pairs Chart"></td>
                                <td>USDINR</td>
                                <td>6.02</td>
                                <td>0.003</td>
                                <td><span class="btn gradient1">0.63%</span></td>
                                <td><span class="btn gradient2">2.63%</span></td>
                                <td><span class="btn gradient3">6.43%</span></td>
                                <td><span class="btn gradient4">58.48%</span></td>
                                <td><span class="btn gradient5">11.48%</span></td>
                            </tr>
                            <tr>
                                <td><img src="assets/images/chart/relatedPair.svg" alt="Chart" title="Related Pairs Chart"></td>
                                <td>USDINR</td>
                                <td>6.02</td>
                                <td>0.003</td>
                                <td><span class="btn gradient1">0.63%</span></td>
                                <td><span class="btn gradient2">2.63%</span></td>
                                <td><span class="btn gradient3">6.43%</span></td>
                                <td><span class="btn gradient4">58.48%</span></td>
                                <td><span class="btn gradient5">11.48%</span></td>
                            </tr>
                            <tr>
                                <td><img src="assets/images/chart/relatedPair.svg" alt="Chart" title="Related Pairs Chart"></td>
                                <td>USDINR</td>
                                <td>6.02</td>
                                <td>0.003</td>
                                <td><span class="btn gradient1">0.63%</span></td>
                                <td><span class="btn gradient2">2.63%</span></td>
                                <td><span class="btn gradient3">6.43%</span></td>
                                <td><span class="btn gradient4">58.48%</span></td>
                                <td><span class="btn gradient5">11.48%</span></td>
                            </tr>
                            <tr>
                                <td><img src="assets/images/chart/relatedPair.svg" alt="Chart" title="Related Pairs Chart"></td>
                                <td>USDINR</td>
                                <td>6.02</td>
                                <td>0.003</td>
                                <td><span class="btn gradient1">0.63%</span></td>
                                <td><span class="btn gradient2">2.63%</span></td>
                                <td><span class="btn gradient3">6.43%</span></td>
                                <td><span class="btn gradient4">58.48%</span></td>
                                <td><span class="btn gradient5">11.48%</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="relatedNews">
                <h5 class="mb-4 medium">Related  News</h5>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-lg-6 mb-4">
                            <div class="card-body d-flex align-items-start p-0">
                                <span class="flex-shrink-0"><img src="assets/images/news/related1.png" alt="News" title="News"></span>
                                <div class="flex-grow-1 ms-md-3">
                                    <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                    <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                    <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 mb-4">
                            <div class="card-body d-flex align-items-start p-0">
                                <span class="flex-shrink-0"><img src="assets/images/news/related2.png" alt="News" title="News"></span>
                                <div class="flex-grow-1 ms-md-3">
                                    <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                    <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                    <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="card-body d-flex align-items-start p-0">
                                <span class="flex-shrink-0"><img src="assets/images/news/related2.png" alt="News" title="News"></span>
                                <div class="flex-grow-1 ms-md-3">
                                    <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                    <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                    <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="card-body d-flex align-items-start p-0">
                                <span class="flex-shrink-0"><img src="assets/images/news/related3.png" alt="News" title="News"></span>
                                <div class="flex-grow-1 ms-md-3">
                                    <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                    <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                    <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            summaryTab: 'Summary',
            relatedTab: 'Related',
        }
    },
}
</script>
